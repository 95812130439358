import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useMediaQuery from '@mui/material/useMediaQuery';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import React, { FunctionComponent, useState } from 'react';

import PGELogo from '../../logos/PGELogo';
import Trees from '../../../static/trees.svg';
import { useTranslation } from '../../../hooks/useTranslation';
import { usePromotionContext } from '../../../providers/PromotionProvider';
import ROUTES from '../../../routes';

import { graphql, Link, useStaticQuery, navigate } from 'gatsby';

import TEST_IDS from '../../../constants/test_ids';
import { Box, Collapse, IconButton, Typography } from '@mui/material';


const PgeFooter: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const smallView = useMediaQuery(theme.breakpoints.down('sm'));
  const [moreLinksExpanded, setMoreLinksExpanded] = useState<boolean>(false);
  const { promotion, noFooter } = usePromotionContext();

  const isBrowser = !(typeof window === 'undefined');

  const isOutages =
    isBrowser && window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES;
  const isOutagesMobileApp =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES_MOBILE_APP;
  const isPGEServiceAreaMap =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.PGE_SERVICE_AREA_MAP;

  if (isOutages || isOutagesMobileApp || isPGEServiceAreaMap) {
    return null;
  }

  const transformMenuData = (results: any) => {
    return results.allContentfulFooter.nodes[0];
  };

  const menuData = transformMenuData(
    useStaticQuery<Queries.getFooterContentQueryQuery>(graphql`
      query getFooterContentQuery {
        allContentfulFooter(filter: { node_locale: { eq: "en" } }) {
          nodes {
            policyLinks {
              title
              url
            }
            socialLinks {
              type
              url
            }
            sections {
              id
              title
              links {
                title
                url
              }
            }
          }
        }
      }
    `),
  );

  const toParagraphs = (...nodes: any) => {
    let key = 0;
    const children = nodes.reduce(
      (result: any, node: any) =>
        result.concat(
          node.split('\n').map((paragraph: string) => (
            <span key={`title-${++key}`}>
              {paragraph}
              <br />
            </span>
          )),
        ),
      [],
    );

    return <span>{children}</span>;
  };

  const generateSocial = (nodes: { type: string; url: string }[]) => {
    const children: Array<React.JSX.Element> = [];

    nodes.forEach((node: { type: string; url: string }) => {
      children.push(generateSocialItem(node));
    });

    return (
      <div key={'social-block'} css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0, 1, 0, 1),
      }}>
        {children}
      </div>
    );
  };

  const generateSocialItem = (social: { type: string; url: string }) => {
    const renderIcon = (type: any) => {
      switch (type) {
        case 'facebook':
          return <FacebookIcon />;
        case 'instagram':
          return <InstagramIcon />;
        case 'twitter':
          return <TwitterIcon />;
        case 'linkedin':
          return <LinkedInIcon />;
      }
    };

    return (
      <IconButton
        color={'inherit'}
        size={'small'}
        href={social.url}
        target="_blank"
        key={social.type}
      >
        {renderIcon(social.type)}
      </IconButton>
    );
  };

  const buildLinkElement = (link: { title?: string; url?: string }) => {
    if (!link?.title) {
      return null;
    }
    const title = link.title.trim();

    if (!link?.url) {
      return <>{title}</>;
    }
    const url = link.url.trim();

    const isExternal = url.indexOf('http') === 0;
    const isTelephone = url.indexOf('tel') === 0;

    if (isExternal || isTelephone) {
      return (
        <a
          css={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          href={url}
          aria-label={title}
          rel={isExternal ? 'noreferrer noopener' : undefined}
          target={isExternal ? '_blank' : '_self'}
        >
          {title}
        </a>
      );
    }

    return (
      <Link css={{
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }} to={url}>
        {title}
      </Link>
    );
  };

  const generateBusiness = (links: { title: string; url: string }[]) => {
    const children: Array<React.JSX.Element | null> = [];

    links.forEach((link: { title: string; url: string }, index: number) => {
      children.push(
        link.title ? (
          <Typography variant={'subtitle1'} key={index}>
            {buildLinkElement(link)}
          </Typography>
        ) : null,
      );
      if (index + 1 < links.length) {
        children.push(
          <div
            key={`business-divider-${index}`}
            css={{
              flex: 1,
              height: '100%',
              minWidth: '1px',
              maxWidth: '1px',
              backgroundColor: '#FFF',
              margin: theme.spacing(0, 1.5, 0, 1.5),
            }}
          />,
        );
      }
    });

    return (
      <Box css={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        padding: theme.spacing(0, 1, 0, 1),
      }}>
        {children.filter(Boolean)}
      </Box>
    );
  };

  const renderMenus = (
    section: { title: string; links: { title: string; url: string }[] },
    index: number,
  ) => {
    const elements: Array<React.JSX.Element | null> = [];

    const titleElement = toParagraphs(
      !!section.title ? section.title.replace('\\n', '\n') : '',
    );
    elements.push(
      titleElement ? (
        <div key={`menuContainer-${index}`} css={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(0, 1, 0, 1),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 2, 0, 2),
          },
        }}>
          {!smallView || !!section.title ? (
            <Typography variant={'h5'} css={{
              minHeight: '0',
              margin: theme.spacing(2, 0, 1, 0),
              wordWrap: 'break-word',
              [theme.breakpoints.up('md')]: {
                minHeight: '25px',
                marginBottom: theme.spacing(1),
                wordWrap: 'break-word',
              },
            }}>
              {titleElement}
            </Typography>
          ) : null}
          {section.links.map((link, linkIndex) => {
            return (
              <div
                key={`subtitle-${index}-${linkIndex}`}
                css={{
                  minHeight: '8px',
                  marginBottom: theme.spacing(0.5),
                  whiteSpace: 'nowrap',
                  color: '#FFF',
                }}
              >
                {!link.title ? null : (
                  <Typography variant={'subtitle1'}>
                    {buildLinkElement(link)}
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
      ) : null,
    );

    return elements.filter(Boolean);
  };

  const renderSections = (
    sections: {
      id: string;
      title: string;
      links: { title: string; url: string }[];
    }[],
  ) => {
    let elements: Array<React.JSX.Element>;
    if (isMobile) {
      const { custServiceSection, outagesSection, moreLinks } = sections.reduce(
        (acc: any, section) => {
          if (section.title === 'CUSTOMER SERVICE') {
            acc.custServiceSection = section;
          } else if (
            section.title &&
            !['CUSTOMER_SERVICE', 'COMPANY'].includes(section.title)
          ) {
            acc.outagesSection = section;
          } else {
            acc.moreLinks.push(section);
          }

          return acc;
        },
        {
          custServiceSection: null,
          outagesSection: null,
          moreLinks: [],
        },
      );

      elements = [
        ...[custServiceSection, outagesSection].map(
          (section: any, index: number) => (
            <div key={`footer-section-${index}`} css={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                margin: theme.spacing(0, 0, 0, 0),
                minWidth: 120,
                [theme.breakpoints.up('md')]: {
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  margin: 0,
                },
            }}>
              {renderMenus(section, index)}
            </div>
          ),
        ),
        <div key="footer-section-more-links">
          <Box display="flex">
            <Typography
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                margin: theme.spacing(0, 0, 2, 1),
                fontWeight: 700,
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
              }}
              onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              variant={'subtitle1'}
            >
              {t('MORE_LINKS')}
            </Typography>
            {moreLinksExpanded ? (
              <ExpandLessIcon
                onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              />
            )}
          </Box>
          <Collapse in={moreLinksExpanded}>
            {moreLinks.map((section: any, index: number) => (
              <div
                key={`footer-section-${index + 2}`}
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  margin: theme.spacing(0, 0, 0, 0),
                  minWidth: 120,
                  [theme.breakpoints.up('md')]: {
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    margin: 0,
                  },
                }}
              >
                {renderMenus(section, index)}
              </div>
            ))}
          </Collapse>
        </div>,
      ];
    } else {
      elements = sections.reduce(
        (
          acc: any,
          section: { title: string; links: { title: string; url: string }[] },
          index: number,
        ) => {
          acc.push(
            <div key={`footer-section-${index}`} css={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              margin: theme.spacing(0, 0, 0, 0),
              minWidth: 120,
              [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
                flexDirection: 'row',
                margin: 0,
              },
            }}>
              {renderMenus(section, index)}
            </div>,
          );
          if (index + 1 < sections.length) {
            acc.push(
              <div key={`divider-${index}`} css={{
                flex: 0,
                height: '100%',
                width: '100%',
                backgroundColor: '#FFFFFF',
                margin: theme.spacing(0, 0, 0, 0),
                [theme.breakpoints.up('md')]: {
                  flex: 1,
                  maxHeight: '100%',
                  minHeight: '50px',
                  maxWidth: '2px',
                  minWidth: '2px',
                  margin: theme.spacing(1, 1, 1, 1),
                },
              }} />,
            );
          }
          return acc;
        },
        [],
      );
    }

    return (
      <Box
        key={'footer-rendered-sections'}
        display={'flex'}
        flexDirection={'column'}
      >
        {
          <div css={{
            backgroundColor: '#0371b4',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '100%',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-start',
            },
          }}>
            {elements}
          </div>
        }
        <Box
          css={{
            backgroundColor: '#0371b4',
          }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={1}
          mb={1}
        >
          {generateBusiness(menuData.policyLinks)}
          {generateSocial(menuData.socialLinks)}
        </Box>
      </Box>
    );
  };

  return (
    <div
      css={{
        color: '#fff',
        flex: 1,
      }}
      data-testid={TEST_IDS.PGE_FOOTER}
      data-swiftype-index="false"
    >
      {!promotion && (
        <>
          <div css={{
            display: 'flex',
            height: '200px',
          }}>
            <Trees style={{ width: '100%', minHeight: 200, height: 200 }} />
          </div>
          <div css={{
            backgroundColor: '#0371b4',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            padding: theme.spacing(0, 0, 2, 0),
          }}>
            <div css={{
              flexGrow: 1,
            }} />
            {renderSections(menuData.sections)}
            <div css={{
              flexGrow: 1,
            }} />
          </div>
        </>
      )}
      {promotion && (
        <>
          <div css={{
            display: 'flex',
            height: theme.typography.pxToRem(199),
            '& > svg': {
              width: '100%',
              minHeight: 200,
              height: 200,
              '& defs pattern path': {
                fill: '#f0f0f0',
              },
            },
          }}>
            <Trees />
          </div>
          <div
            css={{
              backgroundColor: '#f0f0f0',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            {!noFooter && (
              <Box
                css={{
                  backgroundColor: '#f0f0f0',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                  },
                }}
                mt={1}
                mb={1}
              >
                <Box css={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  alignItems: 'center',
                  padding: theme.spacing(0, 1, 0, 1),
                }}>
                  <Typography variant={'subtitle2'}>
                    <Link
                      css={{
                        color: '#0371B4',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      to={ROUTES.PRIVACY_POLICY}
                    >
                      PRIVACY
                    </Link>
                  </Typography>
                  <div css={{
                    flex: 1,
                    height: '50%',
                    minWidth: '1px',
                    maxWidth: '1px',
                    backgroundColor: '#006DBA',
                    margin: theme.spacing(0, 1.5, 0, 1.5),
                    [theme.breakpoints.down(314)]: {
                      margin: theme.spacing(0, 0.75, 0, 0.75),
                      height: '80%',
                    },
                  }} />
                  <Typography variant={'subtitle2'}>
                    <Link
                      css={{
                        color: '#0371B4',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      to={ROUTES.LEGAL_NOTICES}
                    >
                      LEGAL
                    </Link>
                  </Typography>
                  <div css={{
                    flex: 1,
                    height: '50%',
                    minWidth: '1px',
                    maxWidth: '1px',
                    backgroundColor: '#006DBA',
                    margin: theme.spacing(0, 1.5, 0, 1.5),
                    [theme.breakpoints.down(314)]: {
                      margin: theme.spacing(0, 0.75, 0, 0.75),
                      height: '80%',
                    },
                  }} />
                  <Typography variant={'subtitle2'}>
                    <Link css={{
                      color: '#0371B4',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }} to={ROUTES.HOME}>
                      portlandgeneral.com
                    </Link>
                  </Typography>
                </Box>
                <div key={'social-block'} css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: theme.spacing(0, 1, 0, 1),
                }}>
                  <IconButton
                    aria-label={t('NAVIGATE_HOME')}
                    onClick={async (e: any) => {
                      e.preventDefault();
                      await navigate(ROUTES.HOME);
                    }}
                    size="large"
                  >
                    <PGELogo
                      css={{
                        fontSize: '3.5rem',
                      }}
                      color="#006DBA"
                      viewBox="0 0 288 288"
                    />
                  </IconButton>
                </div>
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PgeFooter;
